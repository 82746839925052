import images from '../images';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

const links = [
    {
        path: '/',
        title: 'Home Page'
    },
    {
        path: '/about',
        title: 'About Us'
    },
    {
        path: '/portfolio',
        title: 'Our Portfolio'
    },
    {
        path: '/products-and-services',
        title: 'Products & Services'
    },
    {
        path: '/facility-management',
        title: 'Facility Management'
    }
];

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <div className="header">
            <img style={{ aspectRatio: '1 / .33' }} className="logo up" src={images.logo} alt="logo" />
            <div className="links">
                {links.map((link, idx) => (
                    <NavLink key={idx} to={link.path}>{link.title}</NavLink>
                ))}
            </div>
            <div className='dropdown-menu' onClick={() => setOpenMenu(prev => !prev)}>
                <MenuIcon className='dropdown-icon' style={{ fontSize: 32, fontWeight: 'bold' }} />
                <div className='open-menu-links' style={openMenu
                    ? { opacity: 1, top: '100%' }
                    : { opacity: 0, top: '-300%' }
                }>
                    {links.map((link, idx) => (
                        <NavLink key={idx} to={link.path}>{link.title}</NavLink>
                    ))}
                </div>
            </div>
            <img className="logo" src={images.rLogo} alt="logo" />
        </div>
    );
};

export default Header;