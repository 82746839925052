import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEffect, useState } from 'react';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import AdsClickIcon from '@mui/icons-material/AdsClick';

const FallBack = ({ content }) => (
  <div className='fallback'>{content}</div>
);

const PasswordField = ({ value, onChange, submit }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <input className='password-field' placeholder='Enter the password to continue'
          type={show ? 'text' : 'password'} value={value} onChange={onChange} onKeyUp={submit} />
        <span className='password-icon' onClick={() => {
          const field = document.querySelector('.password-field');
          setShow(prev => !prev);
          field.focus();
        }}>
          {show ? <KeyOffIcon /> : <KeyIcon />}
        </span>
      </div>
      <span className='error-message' id='error'>Wrong Password!</span>
    </>
  );
}

const FacilityManagement = () => {
  const [currentReport, setCurrentReport] = useState(-1);
  const [reports, setReports] = useState({
    loading: true,
    data: []
  });
  const [token, setToken] = useState({ loading: true, data: '' });
  const [accepted, setAccepted] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    fetch(
      'http://sama-tech.ae:5200/reports/5992a4ee-650e-44b5-8b28-535acd19abb4'
    ).then(res => res.json()).then(
      data => {
        console.log(data);
        setReports({
          loading: false,
          data: data
        });
      }).catch(err => console.log(err.message));
  }, []);

  useEffect(() => {
    if (currentReport >= 0) {
      const body = JSON.stringify({
        datasets: [{ id: reports.data[currentReport].datasetId }],
        reports: [{ id: reports.data[currentReport].id }]
      });
      console.log(body);
      fetch(
        'http://sama-tech.ae:5200/token',
        {
          method: 'POST',
          body: body,
          headers: { 'Content-Type': 'application/json' }
        }
      ).then(res => res.json()).then(
        data => setToken({
          loading: false,
          data: data.token
        })
      ).catch(err => alert(err.message));
    }
  }, [currentReport, reports.data]);

  return (reports.loading
    ? <FallBack content={'Loading Data List...'} />
    : <>
      {reports.data?.length > 0
        ? <div className='cards'>
          {reports.data.map((r, i) => (
            <div className={`card ${i === currentReport ? 'active' : null}`} key={i}
              onClick={() => {
                setToken({ ...token, loading: true });
                setAccepted(false);
                setCurrentReport(i);
              }}>
              {r.name}
            </div>
          ))}
        </div>
        : <FallBack content={'No Data'} />}
      {currentReport >= 0
        ? accepted
          ? token.loading
            ? <FallBack content={'Loading Report...'} />
            : <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: reports.data[currentReport]?.id,
                embedUrl: reports.data[currentReport]?.embedUrl,
                accessToken: token.data,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    }
                  }
                }
              }}

              eventHandlers={
                new Map([
                  ['loaded', function () { console.log('Report loaded'); }],
                  ['rendered', function () { console.log('Report rendered'); }],
                  ['error', function (event) { console.log(event.detail); }],
                  ['visualClicked', () => console.log('visual clicked')],
                  ['pageChanged', (event) => console.log(event)],
                ])
              }

              cssClassName={"dashboard-container"}

              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          : <PasswordField value={password} onChange={(e) => setPassword(e.target.value)} submit={
            (e) => {
              if (e.key === 'Enter') {
                if (password === 'Sama.Tech.AE@0000') setAccepted(true);
                else document.getElementById('error').style.opacity = 1;
                setPassword('');
              } else {
                document.getElementById('error').style.opacity = 0;
              }
            }} />
        : <div className='info'>
          <AdsClickIcon style={{ color: 'var(--fg)', fontSize: 32 }} />
          <FallBack content={'Click to Choose one.'} />
        </div>}
    </>
  );
};

export default FacilityManagement;