import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import ScrollButton from "./ScrollButton";


// eslint-disable-next-line react/prop-types
const Root = ({ children }) => {
  const [scrollValue, setScrollValue] = useState({
    pos: 0,
    scroll: 0
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let pos = document.documentElement.scrollTop;
      let calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let newScrollValue = Math.round((pos * 100) / calcHeight);
      setScrollValue({ pos: pos, scroll: newScrollValue });
    });
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer />
      {scrollValue.pos > 100 && <ScrollButton value={scrollValue.scroll} />}
    </>
  );
};

export default Root;